
<template>
  <LogoutPage :message="message" />
</template>

<script>
import { LogoutPage } from '@cendyn/cendyn-frontend-kit';

export default {
  name: 'Logout',
  components: {
    LogoutPage
  },
  data() {
    return {
      message: 'Hang on a moment while we sign you out.'
    };
  },
  created() {
    this.logout();
  },
  methods: {
    async logout () {
      await this.$auth.signOut();
    }
  }
};
</script>

<style></style>
